function themeItOnTime() {
  const isDark = localStorage.getItem("darkMode");
  const timeNow = new Date().toLocaleTimeString("de", {
    hour12: false,
    hour: "numeric",
    minute: "numeric",
  });
  const timeNum = parseInt(timeNow, 10);

  if (timeNum > 18 || timeNum < 9) {
    document.body.classList.add("theme--dark");
    localStorage.setItem("darkMode", true);
  }

  if (
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches &&
    localStorage.getItem("darkMode") === null
  ) {
    document.body.classList.add("theme--dark");
    localStorage.setItem("darkMode", true);
  }
}

function setTheme() {
  const isDark = localStorage.getItem("darkMode");
  if (isDark == "true") {
    document.body.classList.add("theme--dark");
  }
}

setTheme();
themeItOnTime();
